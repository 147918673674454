.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
}

body {
  padding: 0;
  margin: 0;
}
